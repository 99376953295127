<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create -->
    <b-modal
      id="modal-create"
      v-model="isModalCreate"
      size="lg"
      centered
      title="Tambah Data Dokumentasi SK"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label for="nomorSkRektor">Nomor SK Rektor:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nomorSkRektor"
            v-model="form.nomorSkRektor"
            type="number"
            required
            max-length="100"
          />
        </b-form-group>
        <b-form-group>
          <label for="tanggalSkRektor">Tanggal SK Rektor:</label
          ><span class="text-danger ml-1">*</span>
          <flat-pickr
            id="tanggalSkRektor"
            class="form-control bg-white"
            :config="basicConfig"
            v-model="form.tanggalSkRektor"
            required
            placeholder="TanggalSK Rektor"
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label for="judulSk">Judul SK:</label
          ><span class="text-danger ml-1">*</span>
          <b-form-textarea
            id="judulSk"
            v-model="form.judulSk"
            rows="3"
            required
          ></b-form-textarea>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group>
              <label for="berlakuMasaRegistrasi">Berlaku Masa Registrasi:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="berlakuMasaRegistrasi"
                v-model="form.berlakuMasaRegistrasi"
                type="number"
                required
                max-length="5"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="berakhirMasaRegistrasi"
                >Berakhir Masa Registrasi:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="berakhirMasaRegistrasi"
                v-model="form.berakhirMasaRegistrasi"
                type="number"
                required
                max-length="5"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <label for="tipeBeasiswa">Tipe Beasiswa:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="tipeBeasiswa"
            class="w-full text-sm"
            placeholder="Pilih Tipe Beasiswa"
            v-model="form.tipeBeasiswa"
            required
            label="keterangan"
            :options="listDataTipeBeasiswa || []"
            :reduce="listDataTipeBeasiswa => listDataTipeBeasiswa.keterangan"
          >
            <template v-slot:option="option">
              <div class="flex justify-between p-1">
                {{ option.keterangan }}
              </div>
            </template>
          </input-datalists>
        </b-form-group>
        <b-row>
          <!-- Lokasi Dokumen -->
          <b-col>
            <b-form-group>
              <label for="lokasiDokumenFisik">Lokasi Dokumen Fisik SK:</label
              ><span class="text-danger ml-1">*</span>
              <input-primary
                id="lokasiDokumenFisik"
                v-model="form.lokasiDokumenFisik"
                required
                max-length="100"
              />
            </b-form-group>
            <b-form-group>
              <label for="lokasiDokumenSoft">Softfile Dokumen SK:</label
              ><span class="text-danger ml-1">*</span>
              <b-form-file
                placeholder="Pilih Dokumen"
                id="lokasiDokumenSoft"
                v-model="form.lokasiDokumenSoft"
                required
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalCreate">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="createData"
              :disabled="!isValidForm"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Create -->
    <!-- BEGIN:Modal Delete -->
    <b-modal
      id="modal-delete"
      v-model="isModalDelete"
      centered
      title="Hapus Data Dokumen SK"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="danger"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <h5 class="text-center">
          Apakah anda yakin ingin menghapus dokumen SK <br />
          <b>{{ modalDelete.nomorSkRektor }}</b
          >?
        </h5>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="isModalDelete = false"
              >Batal</b-btn
            >
          </b-col>
          <b-col>
            <b-btn class="btn-block" variant="success" @click="deleteData"
              >Hapus</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Delete -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Dokumentasi SK</h4>
          </template>
          <template v-slot:body>
            <div class="align-items-center">
              <b-row>
                <b-col>
                  <b-form-input
                    class="w-50"
                    id="filter-input"
                    v-model="filterTable"
                    type="search"
                    placeholder="Cari Data"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <div class="d-inline-block float-right">
                    <b-button @click="showModalCreate" variant="primary"
                      >Tambah Data<i class="ri-add-line ml-2"></i
                    ></b-button>
                  </div>
                </b-col>
              </b-row>
              <b-table
                responsive
                bordered
                :fields="thead"
                :items="listData"
                class="mt-2"
                :busy="isLoadingTable"
                :filter="filterTable"
                :per-page="perPage"
                :current-page="currentPage"
                @filtered="onFiltered"
                show-empty
              >
                <template v-slot:cell(lihatDokumenSk)="listData">
                  <div class="d-inline-block">
                    <b-button
                      class="rounded"
                      variant="primary"
                      v-b-tooltip="'Lihat Dokumen SK'"
                      size="sm"
                      @click="showDokumenSk(listData.item)"
                      ><i class="ri-search-line m-0"></i>Lihat Dokumen</b-button
                    >
                  </div>
                </template>
                <template v-slot:cell(aksi)="listData">
                  <div class="d-inline-block">
                    <b-button
                      class="rounded"
                      variant="danger"
                      v-b-tooltip="'Hapus SK'"
                      size="sm"
                      @click="showModalDelete(listData.item)"
                      ><i class="ri-delete-bin-line m-0"></i>Hapus</b-button
                    >
                  </div>
                </template>
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #empty>
                  <div
                    class="
                      bg-light
                      text-danger
                      font-weight-bold
                      mx-auto
                      text-center
                    "
                  >
                    Data tidak ditemukan
                  </div>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Per page:"
                    label-for="per-page-select"
                    label-size="sm"
                    class="d-flex"
                  >
                    <b-form-select
                      class="ml-2"
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-pagination
                    class="float-right"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from 'vuex'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'master.jenisPks',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.nomorSkRektor &&
        this.form.tanggalSkRektor &&
        this.form.judulSk &&
        this.form.berlakuMasaRegistrasi &&
        this.form.berakhirMasaRegistrasi &&
        this.form.tipeBeasiswa &&
        this.form.lokasiDokumenFisik &&
        this.form.lokasiDokumenSoft !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Reset Value
     */
    resetForm() {
      this.form.nomorSkRektor = null
      this.form.tanggalSkRektor = null
      this.form.judulSk = null
      this.form.berlakuMasaRegistrasi = null
      this.form.berakhirMasaRegistrasi = null
      this.form.tipeBeasiswa = null
      this.form.lokasiDokumenFisik = null
      this.form.lokasiDokumenSoft = null
    },
    /**
     * Create data
     */
    showModalCreate() {
      this.resetForm()
      this.isModalCreate = true
    },
    closeModalCreate() {
      this.resetForm()
      this.isModalCreate = false
    },
    async createData() {
      this.isModalCreate = false
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Delete data
     */
    showModalDelete(data) {
      this.modalDelete.nomorSkRektor = data.nomorSkRektor
      this.resetForm()
      this.isModalDelete = true
    },
    async deleteData() {
      this.isModalDelete = false
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Show dokumen SK
     */
    async showDokumenSk() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Innit Data
     */
    async innitData() {
      // innit data code...
    }
  },
  data() {
    return {
      // Tabel Options
      isLoadingTable: false,
      filterTable: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // Date Picker
      basicConfig: {
        dateFormat: 'd-m-Y'
      },
      // isModal
      isModalCreate: false,
      isModalDelete: false,
      // List Data
      listData: [
        {
          id: 1,
          nomorSkRektor: 'B/4253/UN31.WR3/KM.01.00/2022',
          tanggalSkRektor: '2022-09-09',
          judulSk:
            'Penandaan Beasiswa Mahasiswa Berprestasi Triwulan Ketiga Tahun',
          berlakuMasaRegistrasi: '20222',
          berakhirMasaRegistrasi: '20224',
          tipeBeasiswa: 'PUSAT',
          lokasiDokumenFisik: 'D:\\Beasiswa\\BEASISWA 20222\\Prestasi 20222'
        }
      ],
      listDataTipeBeasiswa: [
        {
          id: 1,
          keterangan: 'LANGSUNG'
        },
        {
          id: 2,
          keterangan: 'TIDAK LANGSUNG'
        }
      ],
      // Form
      form: {
        nomorSkRektor: null,
        tanggalSkRektor: null,
        judulSk: null,
        berlakuMasaRegistrasi: null,
        berakhirMasaRegistrasi: null,
        tipeBeasiswa: null,
        lokasiDokumenFisik: null,
        lokasiDokumenSoft: null
      },
      // Modal Delete
      modalDelete: {
        nomorSkRektor: null
      },
      // Table
      thead: [
        {
          label: 'Nomor SK Rektor',
          key: 'nomorSkRektor',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal SK Rektor',
          key: 'tanggalSkRektor',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Judul SK',
          key: 'judulSk',
          class: 'text-center w-50',
          sortable: true
        },
        {
          label: 'Masa Berlaku',
          key: 'berlakuMasaRegistrasi',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Masa Berakhir',
          key: 'berakhirMasaRegistrasi',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tipe Beasiswa',
          key: 'tipeBeasiswa',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Lokasi Dokumen Fisik',
          key: 'lokasiDokumenFisik',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Lihat Dokumen SK',
          key: 'lihatDokumenSk',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.KERJASAMA_DOKUMEN_SK.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
